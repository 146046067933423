import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="38"
      height="30"
      viewBox="0 0 38 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-5-7h48v48H-5z" />
        <g stroke="#4468FF" strokeWidth="3">
          <path strokeLinecap="square" d="M1 19h36V1H1z" />
          <path d="M19 14a4 4 0 1 0-.001-8.001A4 4 0 0 0 19 14zM30 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM8 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          <path strokeLinecap="square" d="M37 24H1M37 29H1" />
        </g>
      </g>
    </svg>
  );
};

export default React.memo(Icon);
