import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-7-8h48v48H-7z" />
        <path
          d="M31.853 3.702l.234.234a3.039 3.039 0 0 1 0 4.297l-9.775 9.775-4.531-4.531 9.775-9.775a3.039 3.039 0 0 1 4.297 0zM20.046 15.743l-9.262 9.263M8.224 24.43l2.56.574.575 2.562-6.407 4.423L3.8 30.837z"
          stroke="#4468FF"
          strokeWidth="3"
        />
        <path
          d="M15.043 3.264C13.164 1.486 10.728.87 7.734 1.414l-.097.575 3.907 3.905-1.234 4.603-4.6 1.232L1.79 7.811l-.566.087c-.5 3.228.118 5.671 1.853 7.33 2.04 1.951 5.428 2.304 8.364.824l13.533 13.365c1.157 1.158 3.044 1.38 4.311.343a3.13 3.13 0 0 0 .24-4.641l-13.66-13.492c1.48-2.935 1.32-6.335-.823-8.363z"
          stroke="#4468FF"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default React.memo(Icon);
