import React, { useState } from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { SecondaryButton } from '../components/Buttons/SecondaryButton';
import { Offers } from '../components/Carousels/Offers';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { ContentSection } from '../components/ContentSection/ContentSection';
import { DedicatedSupport } from '../components/DedicatedSupport/DedicatedSupport';
import EarnMore from '../components/EarnMore/EarnMore';
import JoinForm from '../components/Forms/Join';
import Modal from '../components/Modal/index';
import heroBGImage from '../images/Affiliate/background.jpg';
import dashboardMobileImage from '../images/Affiliate/dashboard-mobile.png';
import dashboardImage from '../images/Affiliate/dashboard.jpg';
import paymentHistoryMobileImage from '../images/Affiliate/payment-history-mobile.png';
import paymentHistoryImage from '../images/Affiliate/payments.jpg';
import ArrowSmallIcon from '../images/ArrowSmall';
import PerformIcon from '../images/Icons/Icon-3';
import ToolsIcon from '../images/Icons/Icon-6';

import style from '../styles/pages/affiliate.scss';

import cn from 'classnames';

interface IAffiliateProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
}

const Affiliate = ({ data, location }: IAffiliateProps) => {
  const [isJoinModalShown, setJoinModalShown] = useState(false);
  const [trafficType, setTrafficType] = useState('');

  // Join Modal Toggle
  const showJoinModalWithStepSell = () => {
    setJoinModalShown(true);
    setTrafficType('sell_leads');
  };

  const hideJoinModal = () => {
    setJoinModalShown(false);
    setTrafficType('');
  };

  return (
    <>
      <MetaData location={location} data={{}} />
      <Modal
        className={style.joinModal}
        isShown={isJoinModalShown}
        isCloseButtonShown
        onClose={hideJoinModal}
      >
        <JoinForm trafficType={trafficType} />
      </Modal>
      <Layout isHome>
        <section className={style.hero}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-md-3 col-lg-5 col-xl-6 order-md-last">
                <img src={heroBGImage} alt="Background Lady" />
              </div>
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className={style.headerTextBox}>
                  <h1>
                    Get the results you need.
                    <small>
                      Find out why Lead Economy is trusted by thousands of affiliates world-wide.
                    </small>
                  </h1>
                  <SecondaryButton onClick={showJoinModalWithStepSell}>
                    Start Now <ArrowSmallIcon />
                  </SecondaryButton>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={style.dashboard}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h2>
                  What makes us different.
                  <small>
                    After 10 years of perfecting the art, we’re now able to consistently{' '}
                    <br className="d-none d-md-block" />
                    deliver the best results in the industry.
                  </small>
                </h2>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <ContentSection className={style.col} inViewAnimationClass={style.isInView}>
                  <div>
                    <ToolsIcon />
                    <h5>Superior Technology</h5>
                    <p>
                      All of our technology is built and maintained in-house. Get more leads sold
                      with no down time.
                    </p>
                  </div>
                </ContentSection>
                <ContentSection className={style.col} inViewAnimationClass={style.isInView}>
                  <div>
                    <PerformIcon />
                    <h5>Vast Network</h5>
                    <p>
                      For 10 years we’ve cultivated deep relationships with quality lenders and
                      advertisers who trust us to deliver high quality leads.
                    </p>
                  </div>
                </ContentSection>
              </div>
              <div className="col-12 col-sm-6 col-lg-7 col-xl-6 offset-lg-1 offset-xl-3">
                <img
                  className={cn('d-none d-sm-block', style.paymentImage)}
                  src={paymentHistoryImage}
                  alt="Dashboard"
                />
                <img
                  className={cn('d-sm-none', style.paymentImage)}
                  src={paymentHistoryMobileImage}
                  alt="Dashboard"
                />
                <img
                  className={cn('d-none d-sm-block', style.dashboardImage)}
                  src={dashboardImage}
                  alt="Dashboard"
                />
                <img
                  className={cn('d-sm-none', style.dashboardImage)}
                  src={dashboardMobileImage}
                  alt="Dashboard"
                />
              </div>
            </div>
          </div>
        </section>

        <Offers className={style.offersCarousel} />
        <DedicatedSupport />
        <EarnMore />
        <BigBanner />
      </Layout>
    </>
  );
};

export default Affiliate;
