import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.569 33.348a3.527 3.527 0 1 1-7.053 0 3.527 3.527 0 0 1 7.053 0zM27.076 33.348a3.527 3.527 0 1 1-7.053 0 3.527 3.527 0 0 1 7.053 0zM27.076 13.544a3.527 3.527 0 1 1-7.053 0 3.527 3.527 0 0 1 7.053 0zM38.584 33.348a3.527 3.527 0 1 1-7.053 0 3.527 3.527 0 0 1 7.053 0zM21.323 23.24a3.527 3.527 0 0 1-7.051 0 3.526 3.526 0 1 1 7.05 0zM32.83 23.24a3.528 3.528 0 0 1-3.526 3.528 3.528 3.528 0 0 1 0-7.054 3.527 3.527 0 0 1 3.527 3.526z"
          stroke="#4468FF"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default React.memo(Icon);
