import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="26"
      height="44"
      viewBox="0 0 26 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-11-2h48v48h-48z" />
        <g stroke="#4468FF" strokeWidth="3">
          <path d="M25 22c0 6.627-5.373 12-12 12S1 28.627 1 22s5.373-12 12-12 12 5.373 12 12z" />
          <path d="M18 33.005L17.022 43H8.978L8 33M8 11l.978-10h8.044L18 11M13.255 14.166v7.36l-4.567 4.155" />
        </g>
      </g>
    </svg>
  );
};

export default React.memo(Icon);
