import React, { Component } from 'react';

import { ContentSection } from '../../components/ContentSection/ContentSection';
import offerDesktop1 from '../../images/Carousels/Offers/offer-d-1-new.jpg';
import offerDesktop2 from '../../images/Carousels/Offers/offer-d-2-new.jpg';
import offerDesktop3 from '../../images/Carousels/Offers/offer-d-3-new.jpg';
import offerDesktop4 from '../../images/Carousels/Offers/offer-d-4-new.jpg';
import offerDesktop5 from '../../images/Carousels/Offers/offer-d-5-new.jpg';
import offerMobile1 from '../../images/Carousels/Offers/offer-m-1.jpg';
import offerMobile2 from '../../images/Carousels/Offers/offer-m-2.jpg';
import offerMobile3 from '../../images/Carousels/Offers/offer-m-3.jpg';
import offerMobile4 from '../../images/Carousels/Offers/offer-m-4.jpg';
import offerMobile5 from '../../images/Carousels/Offers/offer-m-5.jpg';
import EarnIcon from '../../images/Icons/Icon-7';
import PayoutsIcon from '../../images/Icons/Icon-8';

import style from './Offers.scss';

import cn from 'classnames';
import Flickity from 'react-flickity-component';

interface IProps {
  className?: string;
  isTextShown?: boolean;
}

const flickityOptions = {
  cellAlign: 'center',
  fade: true,
  initialIndex: 1,
  prevNextButtons: false,
  wrapAround: true,
};

type TFlickity = Flickity & {
  // TODO: Remove next line when Ivan's PR will be merged in the library
  off: (eventName: string, listener: (selectedIndex: number) => void) => void;
};

export class Offers extends Component<IProps> {
  flkty: TFlickity;

  state = {
    selectedIndex: flickityOptions.initialIndex,
  };

  componentWillUnmount() {
    this.flkty.off('select', this.handleSelectIndex);
  }

  handleSelectIndex = (selectedIndex: number) => {
    this.setState({ selectedIndex });
  };

  createRef = (flickity: TFlickity) => {
    // The flickity package is not typed well
    // So we cannot use React's useRef or createRef methods because `flickityRef` property
    // has type `(ref: Flickity) => void | undefined`
    // created our own `createRef` to silent eslint error:
    // Lambdas are forbidden in JSX attributes due to their rendering performance impact (jsx-no-lambda)
    this.flkty = flickity;

    this.flkty.on('select', this.handleSelectIndex);
  };

  myCustomNext = () => {
    // You can use Flickity API
    this.flkty.next();
  };

  myCustomPrev = () => {
    // You can use Flickity API
    this.flkty.previous();
  };

  render() {
    const { className, isTextShown = true } = this.props;

    return (
      <section className={cn(className, style.carouselWrapper)}>
        <div className="container">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-7">
              <h2>
                Why should you <br className="d-none d-lg-block" />
                work with us
              </h2>
              {isTextShown && (
                <>
                  <ContentSection className={style.col} inViewAnimationClass={style.isInView}>
                    <div>
                      <EarnIcon className={style.icon} />
                      <h5>Get Exclusive Offers</h5>
                      <p>
                        Benefit from higher conversion rates with access to our exclusive offers.
                      </p>
                    </div>
                  </ContentSection>
                  <ContentSection className={style.col} inViewAnimationClass={style.isInView}>
                    <div>
                      <PayoutsIcon className={style.icon} />
                      <h5>No Hold Payouts</h5>
                      <p>Get your earnings on time, every time.</p>
                    </div>
                  </ContentSection>
                </>
              )}
            </div>
            <div className="col-12 col-sm-5">
              <Flickity
                className={style.carousel}
                options={flickityOptions}
                flickityRef={this.createRef}
                // need `static` to fix console error
                static
              >
                <div className={cn(style.post)}>
                  <img className="d-sm-none" src={offerMobile1} alt="Offer" />
                  <img className="d-none d-sm-block" src={offerDesktop1} alt="Offer" />
                </div>
                <div className={cn(style.post)}>
                  <img className="d-sm-none" src={offerMobile2} alt="Offer" />
                  <img className="d-none d-sm-block" src={offerDesktop2} alt="Offer" />
                </div>
                <div className={cn(style.post)}>
                  <img className="d-sm-none" src={offerMobile3} alt="Offer" />
                  <img className="d-none d-sm-block" src={offerDesktop3} alt="Offer" />
                </div>
                <div className={cn(style.post)}>
                  <img className="d-sm-none" src={offerMobile4} alt="Offer" />
                  <img className="d-none d-sm-block" src={offerDesktop4} alt="Offer" />
                </div>
                <div className={cn(style.post)}>
                  <img className="d-sm-none" src={offerMobile5} alt="Offer" />
                  <img className="d-none d-sm-block" src={offerDesktop5} alt="Offer" />
                </div>
              </Flickity>
              {/* <div className="d-flex flex-wrap">
                <button
                  className={cn(
                    'col',
                    style.prevButton,
                    this.state.selectedIndex === 0 && style.disabled
                  )}
                  onClick={this.myCustomPrev}
                >
                  <ArrowIcon rotate={180} />
                </button>
                <button className={cn('col', style.nextButton)} onClick={this.myCustomNext}>
                  <ArrowIcon />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
